import mapVerify from '@/utils/formValidate'

// 获取 更多查询字段列表
export const getMoreQueryFormData = (that, formModel) => {
  const moreQueryData = [
    // 车间编码
    {
      name: 'workshopCode',
      span: 12,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.workshopCode')
    },
    // 车间名称
    {
      name: 'workshopName',
      span: 12,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.workshopName')
    },
    // 所属工厂
    {
      name: 'factoryId',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.belongingFactory'),
      filterable: true,
      data: that.factoryList
    }
  ]
  moreQueryData.forEach(item => {
    if (!item.mchange) {
      item.mchange = that.onFormChange
    }
  })
  return moreQueryData
}
// 查询列表列表
export const getSearchTableItem = (that, options) => {
  const searchTableItem = [
    // 车间编码
    {
      prop: 'workshopCode',
      label: that.$t('lang.gles.base.workshopCode'),
      isShow: true,
      sortable: true
    },
    // 车间名称
    {
      prop: 'workshopName',
      label: that.$t('lang.gles.base.workshopName'),
      isShow: true
    },
    // 所属工厂
    {
      prop: 'factoryName',
      label: that.$t('lang.gles.base.belongingFactory'),
      isShow: true
    },
    // 车间描述
    {
      prop: 'description',
      label: `${that.$t('lang.gles.base.workshopDesc')}`,
      isShow: true,
      showOverflowTooltip: true
    }
  ]
  searchTableItem.forEach((item) => (item.showOverflowTooltip = true))
  return searchTableItem
}

// 编辑弹框 基础信息
export const getEditBaseFormData = (that, row) => {
  const formData = [
    // 车间编码
    {
      name: 'workshopCode',
      value: row.workshopCode || '',
      span: 24,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.workshopCode'),
      rules: mapVerify(['required', 'inputCode']),
      appendSlotName: 'inputCodeTipIcon',
      class: 'tip-icon'
    },
    // 车间名称
    {
      name: 'workshopName',
      value: row.workshopName || '',
      span: 24,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.workshopName'),
      placeholder: '200个字符以内，支持任意字符',
      rules: mapVerify(['required', 'inputLen200'])
    },
    // 所属工厂
    {
      name: 'factoryId',
      value: row.factoryId || '',
      span: 24,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.belongingFactory'),
      data: that.factoryList,
      filterable: true,
      rules: mapVerify(['required'])
    },
    // 车间描述
    {
      name: 'description',
      value: row.description || '',
      span: 24,
      component: 'elInput',
      type: 'textarea',
      rows: 6,
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.workshopDesc'),
      rules: mapVerify(['inputLen500'])
    }
  ]
  formData.forEach(item => {
    if (!item.mchange) {
      item.mchange = that.onFormChange
    }
  })
  return formData
}
